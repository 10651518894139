<template>
  <div class="wrap">
    <Loading v-show="$store.state.isLoading"/>
    <van-form v-show="!$store.state.isLoading" @submit="onSubmit" input-align="right" error-message-align="right">
      <van-field
        readonly
        v-model="userName"
        name="userName"
        label="姓名"
      />
      <van-field
        autosize
        v-if="columns.length =='1'"
        readonly
        v-model="contractNum"
        name="contractNum"
        label="合同号"
      />
      <van-field
        autosize
        v-if="columns.length !='1'"
        readonly
        clickable
        name="contractNum"
        :value="contractNum"
        label="合同号"
        @click="showContractPicker = true"
        right-icon="arrow-down"
      />
      <van-popup v-model="showContractPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="columns"
          @confirm="onContractConfirm"
          @cancel="showContractPicker = false"
        />
      </van-popup>
      <van-field
        readonly
        v-model="vin"
        name="vin"
        label="车架号"
      />
      <van-field
        readonly
        v-model="oldBankValue"
        name="oldBankValue"
        label="原银行名称"
      />
      <van-field
        readonly
        v-model="oldCardNumFormat"
        name="oldCardNumFormat"
        label="原卡号"
      />
      <van-field
        v-if="isReadonly"
        readonly
        v-model="bankValue"
        name="bankValue"
        label="银行名称"
      />
      <van-field
        v-if="!isReadonly"
        readonly
        clickable
        name="bankValue"
        :value="bankValue"
        label="银行名称"
        placeholder="请选择"
        @click="showBankPicker = true"
        right-icon="arrow-down"
        :rules="[{ required: true}]"
      />
      <van-popup v-model="showBankPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="bankColumns"
          @confirm="onBankConfirm"
          @cancel="showBankPicker = false"
        />
      </van-popup>
      <van-field
        :readonly="isReadonly"
        v-model="cardNum"
        name="cardNum"
        label="卡号"
        :formatter="formatter"
        maxlength="19"
        type="number"
        placeholder="请输入新的银行卡号"
        @keyup="cardNumChange"
        :rules="[
          { required: true},
          { validator:validatorCard, message: '请输入16-19位银行卡号' }
        ]"
      />
      <van-field
        v-model="upFile"
        name="upFile"  
        label="附件上传" 
        right-icon="arrow"
        :rules="[{ validator:validatorUp, message: '请选择附件' }]">
        <template #input >
          <span class="spanUpfile" v-if="upFile=='0'" type="default" size="small" @click="handdleUpfile">未上传</span>
          <span class="spanUpfile" v-else type="default" size="small" @click="handdleUpfile">已上传</span>
        </template>
      </van-field>
      <!-- v-if="conType=='huizu'" -->
      <van-field
        v-model="signStatus"
        name="signStatus"  
        label="划款授权书" 
        right-icon="arrow"
        :rules="[{ validator:validatorAuth, message: '请签名划款授权书' }]">
        <template #input>
          <span class="spanUpfile" v-if="signStatus=='已签约'" type="default" size="small" @click="handdleSign">已签约</span>
          <span class="spanUpfile" v-else type="default" size="small" @click="handdleSign">未签约</span>
        </template>
      </van-field>
      <van-field
        v-if="isReadonly"
        readonly
        v-model="currentTime"
        type="currentTime"
        name="申请时间"
        label="申请时间"
        placeholder="申请时间"
        :rules="[{ required: true, message: '请填写申请时间' }]"
      />
      <div class="tips">
        <p>温馨提示：</p>
        <p>1、客户修改银行卡需提供客户本人名下的储蓄卡或借记卡进行变更，非本人名下的银行卡无法使用。</p>
        <p>2、此业务在申请成功后7个工作日内修改完成。</p>
        <p>3、可在合同查询中查看是否已变更。</p>
        <p>4、申请进度可在【我的申请】中查看。</p>
      </div>
      <van-col span="20" offset="2" style="position:fixed;bottom:10px;">
        <van-button :disabled="isReadonly" round block type="danger" native-type="submit">
        提交
        </van-button>
      </van-col>
    </van-form>
  </div>
</template>
<script>
  import moment from 'moment';
  import { dealAccountNoHide } from '@/lib/format';
  import { Toast,Dialog } from 'vant';
  import Loading from "@/components/Loading";
  import {modifyCardInfo,modifyCardSub,upFileShow,upFileSave,getOpenId } from '@/server';
  import {setCookie,getCookie,domainUrl} from '@/lib/utils';
  export default {
    components: {
      Loading
    },
  data() {
    return {
      isReadonly:false,//已提交过后字段是否可编辑
      cardId:this.$store.state.cardId,
      accessToken:this.$store.state.accessToken,
      // openId:localStorage.getItem("openId") ||this.$store.state.openId,
      openId:getCookie('openId'),
      code:this.$route.query.code || '',
      backFlag: this.$store.state.backFlag,//是不是isReadonly返回1:false,0:true
      backFlagNum: this.$store.state.backFlagNum,//上传图片页返回 contractNum
      userName: '',
      contractNum: '',
      vin: '',
      oldCardNum:'',
      oldCardNumFormat:'',
      oldBankValue:'建设银行',
      showCalendar: false,
      cardNum:'',
      bankValue: '',
      upFile:'',
      signStatus:'',
      currentTime:'',
      showContractPicker: false,
      bankColumns: ['建设银行', '中国银行','邮储银行'],
      columns: [],
      showBankPicker: false,
      fileList: [],
      dataList:[],
      imgtype:'changecardf',//附件展示标志
      wxid:'',
      conType:'',
      authorModelData:{}
    };
  },
  mounted(){
    let that = this;
    sessionStorage.setItem('key', 'card2');
    window.addEventListener("popstate", function(e) {  //回调函数中实现需要的功能
    	if(sessionStorage.getItem('key') == 'card2') {
        that.$router.push({ path: '/personalCenter' });
      }
    }, false);
  },
  created(){
    // window.location.reload();
    //如果有openid
    if(this.openId){
      getOpenId({openid:this.openId}).then(res=>{
        if(res.wx_code=='0'){
          this.$store.commit('bindStatus', res.bind_status);
          localStorage.setItem("bindStatus", res.bind_status);
          localStorage.setItem("accessToken", res.access_token);
          this.$store.commit('accessToken', res.access_token);
          // localStorage.setItem("openId", res.openid);
          setCookie('openId',res.openid);
          this.$store.commit('openId', res.openid);
          this.$store.commit('cardId', res.card_id);
          localStorage.setItem("cardId", res.card_id);
          this.cardId = res.card_id;
            this.accessToken = res.access_token;
            this.openId = res.openid;
          if(res.bind_status !=='1'){
            this.$router.push({ path: '/login?redirect=/modifyDebitCard' });
          }else{
            this.getFlagDetail();
          }
        }else{
            Toast.fail({
              message: res.msg,
              duration:'3000'
            });
          }
      })
    } else{
      if(!this.code) {
        this.$store.commit('bindStatus', '1');
        localStorage.setItem("bindStatus", '1');
        let uiCode = encodeURIComponent(domainUrl+'/modifyDebitCard');
        window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxe40be82e1238dfe7&redirect_uri='+uiCode+'&response_type=code&scope=snsapi_userinfo&state=STATE&connect_redirect=1#wechat_redirect';         
      }else{//获取code
        getOpenId({percode:this.code}).then(res=>{
          if(res.wx_code=='0'){
            this.$store.commit('bindStatus', res.bind_status);
            localStorage.setItem("bindStatus", res.bind_status);
            this.$store.commit('accessToken', res.access_token);
            localStorage.setItem("accessToken", res.access_token);
            this.$store.commit('openId', res.openid);
            // localStorage.setItem("openId", res.openid);
            setCookie('openId',res.openid);
            this.$store.commit('cardId', res.card_id);
            localStorage.setItem("cardId", res.card_id);
            this.cardId = res.card_id;
            this.accessToken = res.access_token;
            this.openId = res.openid;
            if(res.bind_status !=='1'){
              this.$router.push({ path: '/login?redirect=/modifyDebitCard' });
            }else{
              this.getFlagDetail();
            } 
          }else{
            Toast.fail({
              message: res.msg,
              duration:'3000'
            });
          }
        });
      }
    }
  },
  methods: {
    getFlagDetail(){
      //信息返显
      if(this.backFlag =="1" || this.backFlag =="2"){
        this.columns=[];
        var res = this.$store.state.modifyDataList;
        if(this.backFlag =="1"){
          this.dataList = res;
          res.map(item => {
            this.columns = [...this.columns,item.contract_number]
          });
        }else{
          modifyCardInfo({access_token:this.accessToken,card_id:this.cardId}).then(res=>{
            this.columns = [];
            this.dataList = res.data;
            res.data.map(item => {
              this.columns = [...this.columns,item.contract_number]
            });
            this.$store.commit('modifyDataList', res.data);
            this.onContractConfirm(this.backFlagNum);
          })
          this.$store.commit('backFlagNum', '');
          this.$store.commit('backFlag', '');
        }
        var imgUrlLength = this.$store.state.imgUrlList.length;
        this.upFile = imgUrlLength;
        var writeParams = this.$store.state.writeData;//已经填写没有提交的数据
        this.contractNum = this.backFlagNum;
        this.authorModelData = writeParams;//授权模板所需字段
        this.userName = writeParams.customername;
        this.conType = writeParams.con_type;
        this.signStatus = writeParams.sign_status;
        this.vin = writeParams.vin;
        this.oldCardNum = writeParams.cardno;
        this.oldBankValue = writeParams.bank;
        this.cardNum = writeParams.cardNum;
        this.bankValue =  writeParams.bankValue;
        this.oldCardNumFormat = this.formatAccountNo(this.oldCardNum);//原银行名称格式化
        var myDate = new Date();
        this.currentTime = this.formatDate(myDate.getTime(),true);
        this.$store.commit('backFlagNum', '');
        this.$store.commit('backFlag', '');
      }else if(this.backFlag =="0"){
        modifyCardInfo({access_token:this.accessToken,card_id:this.cardId}).then(res=>{
          this.columns = [];
          this.dataList = res.data;
          res.data.map(item => {
            this.columns = [...this.columns,item.contract_number]
          });
          this.$store.commit('modifyDataList', res.data);
          this.onContractConfirm(this.backFlagNum);
        })
        this.$store.commit('backFlagNum', '');
        this.$store.commit('backFlag', '');
      }else{//新进来的时候
        this.getDetail();
      }
    },
    validatorUp(val) {
      if(val=='0'){
        return false;
      }
    },
    validatorAuth(val) {
      // if(val=='未签约'&& this.conType == 'huizu'){
      //   return false;
      // }
      if(val=='未签约'){
        return false;
      }
    },
    validatorCard(val,rule) {
      if(val.length<16){
        return false;
      }
    },
    //格式化账号
    formatAccountNo(value, dot, digits){
      return dealAccountNoHide(value, dot, digits)
    },
    formatDate(date, flag) {
      if (!date) return '--';
      if (flag) {
        return moment(date).format('YYYY-MM-DD HH:mm:ss');
      } else {
        return moment(date).format('YYYY-MM-DD');
      }
    },
    formatter(value) {
      // 只能输入数字/\D/g,''
      return value.replace(/\D/g, '');
    },
    getDetail(){
      this.$store.commit('imgUrlList', []);
      this.columns=[];
      var myDate = new Date();
      this.currentTime = this.formatDate(myDate.getTime(),true);
      //信息返显
      const params = {
        access_token:this.accessToken,
        card_id:this.cardId
      }
      let res = this.$store.state.dataListTotal;
      // modifyCardInfo(params).then(res=>{
        this.dataList = res;
        res.map(item => {
          this.columns = [...this.columns,item.contract_number]
        });
        this.$store.commit('modifyDataList', res);
        var data = res[0];
        if(data.wx_code == '200'){//未提交
          this.authorModelData = data;
          this.userName = data.customername;
          // this.phoneNo = data.mobile;
          this.conType = data.con_type;
          this.signStatus = data.sign_status;
          this.vin = data.vin;
          this.contractNum = this.columns[0];
          this.oldCardNum = data.cardno;
          this.oldBankValue = data.bank;
          this.wxid = data.wxid;
          this.upFile = '0'
          this.oldCardNumFormat = this.formatAccountNo(this.oldCardNum);//原银行名称格式化
        }else if(data.wx_code == '506'){//已提交,信息返显且不可修改
          this.showDataDisable(data);
          this.showImg();
        }else{
          this.upFile = '0'
          Toast.fail({
            message: data.msg,
            duration:'3000'
          });
        }
      // });
    },
    //附件展示回显
    showImg(){
      const params = {
        wxid:this.wxid
      }
      upFileShow(params).then(res=>{//wx_code:0-存储成功,其他为错误 wx_code:1[没有读取到图片数据]"
        if(res.wx_code=='0'){
          this.fileList = res.imgurl;
          this.upFile = res.imgurl.length
        }else{
          this.fileList = [];
          this.upFile = '0'
        }
      })
    },
    showDataDisable(data){//已提交过后不能再次提交且信息不能修改
      if(data.deal_status=='0' || data.approval_status=='3'){
        Toast.success({
          message: "审核中",
          duration:'3000'
        });
      }else if(data.deal_status=='1'){
        Toast.success({
          message: "审核通过",
          duration:'3000'
        });
      }
        this.authorModelData = data;
        this.userName = data.customername;
        // this.phoneNo = data.mobile;
        this.conType = data.con_type;
        this.signStatus = data.sign_status;
        this.vin = data.vin;
        this.wxid = data.wxid;
        this.contractNum = data.contract_number;

        this.oldCardNum = data.cardno,
        this.oldBankValue = data.bank,
        this.cardNum = data.newcardno,
        this.bankValue = data.newbank,
        // img_status:'上传成功',
        this.currentTime = data.apply_time;
        this.oldCardNumFormat = this.formatAccountNo(this.oldCardNum);//原银行名称格式化
        this.isReadonly = true;
        //data.approval_status,//0审核中/1通过/2退回/3退回再次申请
    },
    onBankConfirm(value) {
      var writeParams = this.$store.state.writeData;
      if(this.bankValue !==value){
        this.authorModelData.sign_status='未签约';
        writeParams.sign_status='未签约';
        this.signStatus = '未签约';
        // console.log("确定"+JSON.stringify(this.authorModelData))
      }
      this.bankValue = value;
      this.showBankPicker = false;
    },
    cardNumChange(value) {
      var writeParams = this.$store.state.writeData;
      if(this.cardNum !==value){
        this.authorModelData.sign_status='未签约';
        writeParams.sign_status='未签约';
        this.signStatus = '未签约';
      }
    },
    onContractConfirm(value) {
      this.fileList = [];
      this.upFile = '0'
      this.$store.commit('backFlag', '');
      this.$store.commit('imgUrlList', []);
      var dataList = this.dataList;
      var wxCode = '';
      var index = '';
      this.contractNum = value;
      this.showContractPicker = false;
      this.dataList.map((item,i) => {
        if(item.contract_number == value){
          wxCode = item.wx_code;
          index = i
        }
      });
      this.authorModelData = this.dataList[index];
      this.vin = this.dataList[index].vin;
      this.userName = this.dataList[index].customername;
      // this.phoneNo = this.dataList[index].mobile;
      this.conType = this.dataList[index].con_type;
      this.signStatus = this.dataList[index].sign_status
      this.oldCardNum = this.dataList[index].cardno;
      this.oldBankValue = this.dataList[index].bank;
      this.wxid = this.dataList[index].wxid;
      this.oldCardNumFormat = this.formatAccountNo(this.oldCardNum);//原银行名称格式化
      if(wxCode=='506'){
        this.showDataDisable(dataList[index]);
        this.showImg();
      }else if(wxCode=='200'){
        // this.upFile = '0'
        var myDate = new Date();
        this.cardNum = '';
        this.bankValue = '';
        this.currentTime = this.formatDate(myDate.getTime(),true);
        this.isReadonly = false;
      };
    },
    handdleSign(){
      const params = {...this.authorModelData,
        cardNum:this.cardNum,
        bankValue: this.bankValue,
        applydate:this.currentTime,
        isReadonly:this.isReadonly
        // refundCard:this.cardNum,
        // refundBank:this.bankValue
      }
      console.log("去签名"+JSON.stringify(this.authorModelData))
      console.log("去签名"+JSON.stringify(params))
      if(this.bankValue ==''){
        Toast.fail({
          message: "请先选择银行",
          duration:'3000'
        });
      }else if(this.cardNum ==''){
        Toast.fail({
          message: "请先输入银行卡号",
          duration:'3000'
        });
      }else{
        this.$store.commit('authorData', params);
        this.$store.commit('writeData', params);
        this.$router.push({path: '/transferAuthorization'})
      }
    },
    handdleUpfile(){
      const params = {...this.authorModelData,
        cardNum:this.cardNum,
        bankValue: this.bankValue,
      }
      this.$store.commit('writeData', params);
      this.$store.commit('fileList', this.fileList);
      this.$router.push({ 
        path: '/upFile',
        query: { 
          conNo: this.contractNum,
          isReadonly:this.isReadonly,
          imgtype:this.imgtype
        }});
    },
    onSubmit(values) {
      let authorData = this.$store.state.authorData;
      var myDate = new Date();
      this.currentTime = this.formatDate(myDate.getTime(),true);
      const params = {
        access_token:this.accessToken,
        card_id:this.cardId,
        customername:values.userName,
        con_type:this.conType,
        contract_number:values.contractNum,
        vin:values.vin,
        cardno:this.oldCardNum,
        bank:values.oldBankValue,
        newcardno:values.cardNum,
        newbank:values.bankValue,
        apply_time:this.currentTime,
        // sign_tatus:this.signStatus,
        signimg_url:authorData.signimg_url,
        imgurl:this.$store.state.imgUrlList
      }
      Dialog.confirm({
        message: '申请后无法撤销\n请确认是否提交',
      })
        .then(() => {
          modifyCardSub(params).then(res=>{
            var data = res[0];
            const that = this;
            if(res.status=='200'){
              Toast({
                message: '提交成功！',
                icon: require('@/assets/img/bindSuccess.png'),
                duration:'3000'
              });
              this.$store.commit('imgUrlList', []);
              this.$store.commit('backFlag', '');
              this.$store.commit('writeData', {});
              // this.isReadonly = true;
              // this.getDetail();
              modifyCardInfo({access_token:this.accessToken,card_id:this.cardId}).then(res=>{
                this.columns = [];
                this.dataList = res.data;
                res.data.map(item => {
                  this.columns = [...this.columns,item.contract_number]
                });
                this.$store.commit('modifyDataList', res.data);
                this.onContractConfirm(values.contractNum);
              })
            }else{
              this.$store.commit('imgUrlList', []);
              this.$store.commit('backFlag', '');
              this.$store.commit('writeData', {});
              Toast({
                message: res.msg,
                icon: require('@/assets/img/bindError.png'),
                duration:'3000'
              });
            }
          })
        })
        .catch(() => {
          // on cancel
        });
      // this.onSubmitImg(params);
    },
  },
};
</script>
<style scoped>
.wrap{
    padding: 20px 0px;
    background-color: #fff;
  }
.van-button--default{
  border: 0px;
}
.tips{
  color:#808080;
  font-size:13px;
  margin-top: 20px;
  margin-bottom: 60px;
}
.tips p{
  margin: 6px 15px;
}
</style>
<style>
.wrap .van-field__control{
  color: #666666 !important;
}
#app{
  background: rgba(0,0,0,0) !important;
}
/* #app{
  background: #fff !important;
} */
.spanUpfile{
  width: 100%;
}
</style>